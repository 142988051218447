<template>
	<div class="block">
		<el-form label-position="left" label-width="80px" :model="diary" ref='diary' :rules="rules" class="diary-form" v-if="level==3">
		  <el-form-item label="内容" prop="content">
		      <el-input type="textarea" v-model="diary.content"></el-input>
		  </el-form-item>
		  <el-form-item label="类型" prop="type">
		    <el-input v-model="diary.type"></el-input>
		  </el-form-item>
		  <el-form-item>
		    <el-button type="primary" @click="submitForm('diary')">提交</el-button>
		  </el-form-item>
		</el-form>
	  <el-timeline  v-loading='loading'
	    element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)">
	    <el-timeline-item :timestamp="$moment(diary.createTime,'YYYY-MM-DD hh:ss:mm').startOf('hour').fromNow()" placement="top" v-for="(diary,index) in diaries" :key='index' :icon="'el-icon-grape'" v-model='diaries'>
	      <el-card class="diary-text-content">
			<p v-text="$moment(diary.createTime).format('YYYY-MM-DD hh:ss:mm a')" class="diary-time"></p>
	        <div class="diary-text" v-text="diary.content"></div>
			<p class="diary-footer">「 <span class="diary-type">{{diary.type}}</span> 」<span class="diary-likes" :id="'liked-'+diary.did" @click="likeDiary(diary.did)"><img :src="liked.indexOf(diary.did)!=-1?'/img/kasuie/likes_.png':'/img/kasuie/likes.png'" >{{ diary.likes }}</span></p>
	      </el-card>
	    </el-timeline-item>
	  </el-timeline>
	</div>
</template>

<script>
  export default {
	name: 'DiaryC',
	inject: ['reload'],
    data() {
      return {
		diaries: [],
		liked:[],
		level: this.$store.getters.getUser.level,
		loading: true,
		diary: {
			type: '',
			content: '',
		},
		rules: {
			type: [
				{ required: true, message: '请输入分类', trigger: 'blur' },
			],
			content: [
				{ required: true, message: '请输入内容', trigger: 'blur' },
			],
		}
      };
    },
	methods: {
		getAllDiaries(){
			let that = this
			this.axios.post('diaries').then(function(results){
				that.diaries = results.data
				that.loading = false
			},function(err){
				console.log(err)
			})
		},
		likeDiary(did){
			let that = this
			let el = document.getElementById("liked-"+did)
			if(el.children[0].getAttribute('src')=='/img/kasuie/likes_.png'){
				this.$notify.info({
				    title: '点赞失败',
				    message: '不能重复点赞哦~'
				});
				return false
			}
			if(this.$cookies.isKey('likes')){
				that.$cookies.set("likes", that.$cookies.get("likes")+','+did , "1y")
			}else{
				that.$cookies.set("likes", did , "1y")
			}
			that.liked.push(did)
			for (let s of that.diaries) {
				if(s.did==did){
					s.likes++
				}
			}
			this.axios.get('likeDiary?did='+did).then(function(results){
				console.log(results.data);
			},function(err){
				console.log(err);
			})
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
			  if (valid) {
				this.addDiary()
			  } else {
				console.log('error submit!!');
				return false;
			  }
			});
        },
		addDiary(){
			let that = this
			this.axios.post('addDiary',this.diary).then(function(results){
				if(results.data){
					that.$notify({
						title: '成功',
						message: '添加成功',
						type: 'success'
					});
					that.reload()
				}
			},function(err){
				console.log(err);
			})
		}
	},
	created() {
		if(this.$cookies.isKey('likes')){
			for (let did of this.$cookies.get("likes").split(',')) {
				this.liked.push(parseInt(did))
			}
		}
		this.getAllDiaries()
	},
  };
</script>

<style>
.el-timeline{
	width: 60%;
	margin: 0 auto;
}
.diary-form{
	width: 60%;
	margin: 0 auto;
}
.diary-form .el-input{
	width: 100%;
}
.el-card{
	background-color: var(--theme-background-color);
}
.diary-text-content .el-card__body{
	text-align: start;
	padding: 1em;
}
.el-timeline-item .el-card__body .diary-text{
	color: var(--theme-color);
	font-size: 1.1em;
	font-weight: 500;
	margin: 1em;
}
.el-timeline-item .el-card__body .diary-time{
	color: var(--theme-color);
	font-size: smaller;
	text-align: end;
}
.el-timeline-item .el-card__body .diary-type{
	font-size: .9em;
	font-style: italic;
	text-decoration: underline;
	color: #2679c2;
}
.el-timeline-item__timestamp{
	text-align: start;
	color: #20629c;
}
.diary-footer{
	position: relative;
	color: var(--theme-color);
}
.diary-footer .diary-likes{
	position: absolute;
	right: 0;
	line-height: 1em;
	font-size: 1em;
	cursor: pointer;
	user-select: none;
	color: #3981c0;
}
.diary-footer .diary-likes img{
	width: 1.5em;
	vertical-align: middle;
	margin: 0 .3em;
}
.darkApp .diary-type{
	color: #89b0c1 !important;
}
@media screen and (max-width: 770px) {
	.el-timeline{
		width: 90%;
	}
	.diary-form{
		width: 90%;
	}
}
</style>
