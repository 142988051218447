<template>
	<div class="diary">
		<Nav></Nav>
		<TopCover :pageMsg='pageMsg'></TopCover>
		<DiaryC></DiaryC>
	</div>
</template>

<script>
import DiaryC from '@/components/Diary.vue'
import TopCover from '@/components/TopCover.vue'
import Nav from '@/components/Nav.vue'
export default {
  metaInfo: {
      title: '随心记 - KASUIEの次元',
      meta: [
    	  {
    		  name: 'description',
              content: '随心记',
    	  },
          {
              name: 'keywords',
              content: '随心记，博客日志，碎碎念，更新日志'
          }
      ]
  },
  name: 'Diary',
  data() {
  	return {
  		pageMsg:{
  			title: '随心记',
  			imgUrl: 'https://i.loli.net/2021/07/15/xoIUJtMZFzBPrHd.jpg',
			article: ''
  		},
  	}
  },
  components: {
    DiaryC,
	TopCover,
	Nav
  },
  created() {
  	document.title = 'KASUIEの随心记'
	let title
	setTimeout(function() {
	   	title = document.title
	}, 1000);
	window.onfocus = function () {
		document.title = '恢复正常了...';
	    setTimeout(function() {
			document.title = title
		}, 1000);
	};
	window.onblur = function () {
		setTimeout(function() {
			document.title = '快回来~页面崩溃了'
		}, 5000);
	};
  },
  mounted() {
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''		
	},2000)
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}	
</script>

<style>
.diary{
	position: relative;
}
</style>
